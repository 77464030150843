import styled from 'styled-components'
import { device } from 'src/theme/theme'

export interface WrapperProps {
  backgroundColor: string
  applyBackgroundImage: boolean
  padding: string
  paddingMobile: string
}

export const Wrapper = styled.div<WrapperProps>`
  background-color: ${p => p.backgroundColor};
  padding: ${p => p.padding};
  ${p =>
    p.applyBackgroundImage && {
      backgroundImage:
        'url("https://res.cloudinary.com/cordasemusica/image/upload/f_webp/v1678224794/fundo-professores_btikhn.png")',
      backgroundAttachment: 'fixed',
      backgroundPosition: 'bottom center',
      backgroundRepeat: 'no-repeat',
    }};
  overflow: hidden;

  @media (max-width: 768px) {
    padding: ${p => p.paddingMobile};
  }
`

export const TitleContainer = styled.div<{ marginTitleM?: string; marginTitleD?: string }>`
  margin: ${({ marginTitleM }) => marginTitleM};

  @media ${device.tablet} {
    margin: ${({ marginTitleD }) => marginTitleD};
  }
`
