import styled from 'styled-components'
import { device } from 'src/theme/theme'

export const MultipleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  overflow: hidden;

  .content {
    display: inherit;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    padding: 0 1rem;
    gap: 20px;
  }

  @media (max-width: 768px) {
    justify-content: center;

    span {
      display: none;
    }

    span:first-child {
      display: block;
    }
  }
`
export const ProfileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;
  gap: 20px;
  margin: 1rem;

  @media ${device.tablet} {
    margin: 1rem 0;
    flex-direction: row;
  }

  .item {
    margin-top: 1rem;
    margin: 0 auto;
  }

  .bio {
    width: 100%;

    span {
      @media ${device.tablet} {
        display: flex;
        justify-content: flex-end;
        padding-left: 1rem;
      }
    }
  }

  span {
    width: 100%;
  }

  @media (max-width: 768px) {
    justify-content: center;

    span {
      display: none;
    }

    span:first-child {
      display: block;
      width: 100%;
    }
  }
`

export const ColumnsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  gap: 20px;

  &.tutorial-skeleton {
    gap: 30px;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      gap: 10px;
      flex-direction: column;
    }
  }

  span {
    width: 100%;
  }

  @media (max-width: 768px) {
    justify-content: center;

    span {
      display: none;
    }

    span:first-child {
      display: block;
      width: 100%;
    }
  }

  .player-tutorial {
    flex: 1.33;
  }

  .nav-tutorial {
    flex: 1;
  }
`

export const Module = styled.div`
  display: flex;
  padding: calc(12px + 8px) 12px 12px calc(12px + 8px);

  border-bottom: 1px solid #cccccc;

  .circle {
    margin-right: 8px;
  }

  .title {
    margin-bottom: 0.5rem;
  }
`

export const ContentClassroomWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    padding-left: ${({ theme }) => theme.spacing.huge};
  }

  .tabs {
    display: flex;

    .tab {
      margin-bottom: 1rem;
      margin-right: 1rem;
    }
  }

  .player-container {
    position: relative;
    padding-top: 56.25%;

    .player {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
    }
  }

  .info {
    .title-1 {
      margin: 0.7rem 0;
    }

    .title-2 {
      margin-top: 0;
    }

    .box {
      margin: 1rem 0;
    }
  }
`

export const TeacherWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .player-container {
    position: relative;
    padding-top: 56.25%;
    margin-bottom: ${p => p.theme.spacing.medium};

    .player {
      width: 80%;
      position: absolute;
      top: 10%;
      left: 10%;
      bottom: 0;
    }
  }

  .info {
    overflow: hidden;
    margin-bottom: ${p => p.theme.spacing.huge};

    @media ${device.laptopL} {
      padding: 0;
    }

    .title-bio {
      margin: 1rem 0 0;
    }

    .title-alternative {
      margin: ${p => p.theme.spacing.huge} 0 16px;
    }

    .box {
      margin: 1rem 0 0;
    }
  }
`

export const NewsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .player-container {
    position: relative;
    padding-top: 56.25%;
    margin-bottom: ${p => p.theme.spacing.medium};

    .player {
      width: 90%;
      position: absolute;
      top: 5%;
      left: 5%;
      bottom: 0;
    }
  }

  .info {
    width: 90%;
    overflow: hidden;
    margin: 0 auto ${p => p.theme.spacing.huge};

    @media ${device.laptopL} {
      width: 100%;
    }

    .title-bio {
      margin: 1rem 0 0;
    }

    .box {
      margin: 1rem 0 0;
    }
  }
`

export const CourseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  overflow: hidden;

  .title {
    @media ${device.tablet} {
      margin: 0 0 10px 15px;
    }
  }

  .box {
    margin: 1rem 15px;
  }
`

export const CategoryContainerContainer = styled.div`
  max-width: 1170px;
  margin: 0 auto;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
`
