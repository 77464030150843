import styled from 'styled-components'

export interface CardPropsStyles {
  color: string
  backgroundColor: string
}

const labelHeight = 67

export const Wrapper = styled.a<CardPropsStyles>`
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  margin-right: ${p => p.theme.spacing.medium};
  transition: transform 0.3s ease 0.1s, height 0.3s ease 0.1s;
  transform-origin: center center;
  position: relative;
  overflow: hidden;
  background: ${p => p.backgroundColor};

  h3 {
    color: ${p => p.color};
  }

  &:hover,
  &:focus {
    transition: all 0.3s ease-in-out 0s;

    .maskContainer {
      z-index: 9;
      opacity: 1;
    }
  }

  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  .maskContainer {
    transition: all 0.3s ease 0.1s;
    position: absolute;
    background: ${p => p.theme.colors.palette.overlay70};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 0;

    svg {
      width: 100px;
      height: 100px;

      path {
        stroke: ${p => p.theme.colors.white};
      }
    }
  }
`

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
`

export const TitleContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: ${p => p.theme.spacing.small};
`

export const ItemTitle = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${labelHeight}px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (max-width: 480px) {
    ${p => ({ ...p.theme.size.xs })};
  }

  @media (max-width: 768px) {
    ${p => ({ ...p.theme.size.sm })};
  }
`

export const Label = styled.div`
  background: ${({ theme }) => theme.colors.secondaryBackgroundColor};
  position: absolute;
  top: 0;
  padding: 0.5rem 1rem;

  span {
    color: ${({ theme }) => theme.colors.white};
    ${({ theme }) => ({ ...theme.typography.primary.semiBold })}
  }
`
