import React, { memo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { ArrowWrapper } from './styles'

function NextArrowComponent(arrowProps) {
  const { className, onClick } = arrowProps
  return (
    <ArrowWrapper style={{ right: 0 }} className={className} onClick={onClick}>
      <FontAwesomeIcon icon={faChevronRight} />
    </ArrowWrapper>
  )
}

export const NextArrow = memo(NextArrowComponent)
