import React, { memo } from 'react'
import { TxKeyPath } from 'src/i18n'
import { colors } from 'src/theme/colors'
import theme from 'src/theme/theme'
import { Title, TitleSize } from '../Title'
import {TitleContainer, Wrapper} from './styles'

interface SectionProps {
  titleTx?: TxKeyPath | string
  children: React.ReactNode
  backgroundColor?: string
  applyBackgroundImage?: boolean
  padding?: string
  paddingMobile?: string
  preset?: keyof typeof presets
  marginTitleM?: string
  marginTitleD?: string
}

function SectionComponent(props: SectionProps) {
  const presetStyles = presets[props?.preset || 'light']

  return (
    <Wrapper
      paddingMobile={props.paddingMobile || `${theme.spacing.extraLarge} 0`}
      applyBackgroundImage={!!props?.applyBackgroundImage}
      backgroundColor={props?.backgroundColor || colors.background}
      padding={props?.padding || `${theme.spacing.huge} 0`}
    >
      {props?.titleTx && (
        <TitleContainer marginTitleM={props?.marginTitleM} marginTitleD={props?.marginTitleD}>
          <Title {...presetStyles.title} tx={props.titleTx} />
        </TitleContainer>
      )}
      {props.children}
    </Wrapper>
  )
}

export const Section = memo(SectionComponent)

const presets = {
  light: {
    title: {},
  },
  dark: {
    title: {
      theme: 'light',
      borderColor: colors.white,
    },
  },
  faqTitle: {
    title: {
      size: 'lg' as TitleSize,
      color: colors.primaryTextColor,
      borderColor: colors.white,
      hasBorder: false,
      justify: 'flex-start',
      textAlign: 'left',
    },
  },
  courses: {
    title: {
      size: 'xl' as TitleSize,
      color: colors.primaryTextColor,
      borderColor: colors.white,
      hasBorder: false,
      justify: 'flex-start',
      textAlign: 'left',
      marginMobile: '0 0 5px 15px',
      marginTitle: '0 0 5px 15px'
  },
  },
  coursesTeacher: {
    title: {
      size: 'xl' as TitleSize,
      color: colors.primaryTextColor,
      borderColor: colors.white,
      hasBorder: false,
      justify: 'flex-start',
      textAlign: 'left',
      marginMobile: '0 0 16px',
    },
  },
}
