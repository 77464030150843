import styled from 'styled-components'

interface WrapperProps {
  marginLeft?: number
  marginRight?: number
}

export const Wrapper = styled.div<WrapperProps>`
  margin-left: ${p => (p?.marginLeft !== undefined ? p.marginLeft : '15px')};
  margin-right: ${p => (p?.marginRight !== undefined ? p.marginRight : '0')};

  .slick-list {
    padding: 0 20% 0 0 !important;
    justify-content: flex-start;
  }

  .slick-dots {
    margin-top: ${p => p.theme.spacing.small};
  }

  .slick-dots li button:before {
    font-size: 12px;
  }

  .slick-dots li.slick-active button:before {
    font-size: 18px;
  }

  .slick-track {
    margin-left: 0;
    margin-right: 0;
  }
`

export const ArrowWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${p => p.theme.colors.transparent};
  min-width: 75px;
  height: 100%;
  transition: all 0.2s linear;
  z-index: 3;

  &:hover,
  &:focus {
    background-color: ${p => p.theme.colors.palette.overlay70};
  }

  :before {
    display: none;
  }

  svg {
    fill: ${p => p.theme.colors.white};
    path {
      fill: ${p => p.theme.colors.white};
    }
    width: 40px;
    height: 40px;
  }

  @media (max-width: 768px) {
    min-width: 40px;
    background-color: ${p => p.theme.colors.palette.overlay70};
    height: 30%;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`
