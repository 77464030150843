import React, { memo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { ArrowWrapper } from './styles'

function PrevArrowComponent(arrowProps) {
  const { className, onClick } = arrowProps
  return (
    <ArrowWrapper style={{ left: 0 }} className={className} onClick={onClick}>
      <FontAwesomeIcon icon={faChevronLeft} />
    </ArrowWrapper>
  )
}

export const PrevArrow = memo(PrevArrowComponent)
