import React, { memo } from 'react'
import { colors } from 'src/theme/colors'
import ProgressiveImage from 'react-progressive-graceful-image'
import Link from 'next/link'
import { getImageUrl } from 'src/utils/getImageUrl'
import { getPlaceholderImageUrl } from 'src/utils/getPlaceholderImageUrl'
import { INews, Lesson } from 'src/service/api/api.types'
import { CardPropsStyles, ImageContainer, ItemTitle, Label, TitleContent, Wrapper } from './styles'

export type CardPresets = keyof typeof presets

interface CardProps {
  data: Lesson | INews
  linkType?: 'course' | 'lesson' | 'news'
  preset?: CardPresets
  icon?: string
  imageWidth?: number
  imageHeight?: number
}

function CardComponent({
  data,
  preset,
  linkType = 'course',
  icon,
  imageWidth,
  imageHeight,
}: CardProps) {
  const presetStyles = presets[preset || 'primary']

  const link = {
    course: { pathname: `/curso/${data.title}`, query: `iconUrl=${icon}` },
    lesson: { pathname: `/curso/aula/${data?.slug}` },
    news: { pathname: `/noticias-recentes/${data?.slug}` },
  }

  const wasWatched = 'wasWatched' in data && data.wasWatched

  return (
    <Link legacyBehavior passHref href={link[linkType]}>
      <Wrapper title={data?.title} {...presetStyles}>
        <ImageContainer>
          {wasWatched ? (
            <Label>
              <span>Assistido</span>
            </Label>
          ) : null}

          <div className="maskContainer">
            <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none">
              <path
                d="M11.97 22C17.4928 22 21.97 17.5228 21.97 12C21.97 6.47715 17.4928 2 11.97 2C6.44712 2 1.96997 6.47715 1.96997 12C1.96997 17.5228 6.44712 22 11.97 22Z"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.73999 12.2299V10.5599C8.73999 8.47988 10.21 7.62988 12.01 8.66988L13.46 9.50988L14.91 10.3499C16.71 11.3899 16.71 13.0899 14.91 14.1299L13.46 14.9699L12.01 15.8099C10.21 16.8499 8.73999 15.9999 8.73999 13.9199V12.2299Z"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <ProgressiveImage
            src={getImageUrl(data?.imageUrl, imageWidth || 562, imageHeight || 290)}
            placeholder={getPlaceholderImageUrl(
              data?.imageUrl,
              imageWidth || 562,
              imageHeight || 290,
            )}
            rootMargin="0% 0% 0%"
            threshold={[0.5]}
          >
            {src => <img src={src} alt={data?.title} />}
          </ProgressiveImage>
        </ImageContainer>
        <TitleContent>
          <ItemTitle>{data?.title}</ItemTitle>
        </TitleContent>
      </Wrapper>
    </Link>
  )
}

interface Presets {
  [key: string]: CardPropsStyles
}

const presets: Presets = {
  primary: {
    color: colors.primaryTextColor,
    backgroundColor: colors.white,
  },
  secondary: {
    color: colors.primaryTextColor,
    backgroundColor: colors.palette.neutral300,
  },
}

export const Card = memo(CardComponent)
