import React from 'react'
import Slider, { Settings } from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Wrapper } from './styles'

interface BaseType {
  id: string
}

interface CarouselProps<T> {
  items?: T[]
  settings: Settings
  ItemTemplate: React.ElementType
  marginLeft?: number
  marginRight?: number
  preset?: string
  linkType?: 'course' | 'lesson' | 'news'
}

export function Carousel<T extends BaseType>({ ItemTemplate, ...props }: CarouselProps<T>) {
  const renderCard = (item: T) => (
    <ItemTemplate key={item.id} data={item} preset={props.preset} linkType={props.linkType} />
  )

  const totalItems = props?.items?.length || 0
  const slidesToShow = props?.settings?.slidesToShow || 1
  const infinite = totalItems > slidesToShow

  return (
    <Wrapper marginLeft={props.marginLeft} marginRight={props.marginRight}>
      <Slider {...props.settings} infinite={infinite}>
        {Array.isArray(props?.items) &&
          props.items.length > 0 &&
          props.items.map(item => renderCard(item))}
      </Slider>
    </Wrapper>
  )
}
